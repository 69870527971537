<!-- @format -->

<template>
  <div>
    <div class="w-11.5/12 m-auto pt-4" v-if="complaintStatus == 1">
      <QuillEditor
        style="min-height: 250px"
        class="border-teal-light rounded-b-lg border-opacity-30 border-2 border-top-0"
        toolbar="essential"
        max="5"
        theme="snow"
        content-type="html"
        v-model:content="quillContent"
      />
    </div>
    <small
      v-if="errors.quillContent || contentLimitReached"
      class="text-red mt-1 mx-8"
    >
      {{ errors.quillContent }}
    </small>
    <div class="flex justify-between mx-6 my-4" v-if="complaintStatus == 1">
      <div>
        <div
          v-bind="getRootProps()"
          class="flex items-center justify-end cursor-pointer"
        >
          <input v-bind="getInputProps()" />
          <div
            class="material-icons pl-1 w-7 transform -rotate-45"
            :class="loader ? 'cursor-not-allowed text-gray-400' : ''"
          >
            attachment_outlined
          </div>
          <button
            :disabled="loader"
            class="cursor-pointer"
            :class="loader ? 'cursor-not-allowed text-gray-400' : ''"
          >
            Add Files
          </button>
          <div class="pl-2">
            <small v-if="errors.acceptedFiles" class="text-red">{{
              errors.acceptedFiles
            }}</small>
            <small v-if="!isFileUploaded.flag" class="text-red">{{
              isFileUploaded.msg
            }}</small>
          </div>
        </div>
      </div>
      <button
        class="border hover:text-white px-3 py-2 rounded-md"
        @click="validateAndSubmit"
        :disabled="loader"
        :class="
          loader || contentLimitReached
            ? 'cursor-not-allowed border-gray-400 text-gray-600 hover:bg-gray-400 '
            : ' border-teal text-teal hover:bg-teal'
        "
      >
        Update
      </button>
    </div>

    <div v-if="acceptedFiles.length" class="mx-6">
      <div
        class="p-4 flex w-full gap-4 rounded-md mt-4"
        :class="loader ? 'bg-gray-200 cursor-wait' : 'bg-teal bg-opacity-10'"
      >
        <div class="flex items-center justify-start">
          <span
            v-if="loader"
            class="border-2 border-black rounded-full w-4 h-4 animate-spin"
          ></span>
        </div>
        <div v-for="(item, key) in acceptedFiles" :key="key">
          <div
            class="w-full px-2 rounded-md"
            :class="
              loader
                ? 'bg-gray-400 cursor-wait text-white'
                : ' bg-teal bg-opacity-25'
            "
          >
            <div class="text-md line-clamp-2 capitalize flex items-center">
              {{
                item
                  ? item.name?.length > 35
                    ? item.name?.slice(0, 25) + "..." + item.name?.slice(-8)
                    : item.name
                  : ""
              }}
              <span
                class="material-icons w-5 text-red px-1 cursor-pointer text-lg"
                v-if="!loader"
                @click="removeSingleFile(key)"
                >close</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="complaintStatus != 1" class="pl-6 font-medium text-lg">
      Recent Updates
    </div>
    <div
      class="flex flex-col items-center justify-center text-center w-full py-10"
      v-if="!complaintUpdates?.length"
    >
      <div class="rounded-full p-12 icon-bg">
        <img src="../../../../assets/icons/noData.svg" class="w-32" />
      </div>
      <div class="mt-4 text-teal font-medium flex align-middle">
        <i class="material-icons text-teal w-8">info_outlined</i>
        No Updates Available
      </div>
    </div>

    <Timeline :updatesTimeline="complaintUpdates" :loader="loader" />
  </div>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useDropzone } from "vue3-dropzone";
import Timeline from "@/components/newUi/baseComponents/Timeline.vue";
import { ref, defineProps, toRefs, watch } from "vue";
import { useStore } from "vuex";
import SuccessIcon from "@/assets/icons/success.svg";
import Swal from "sweetalert2";
import { useRoute } from "vue-router";
const { getRootProps, getInputProps, ...rest } = useDropzone({
  onDrop,
  maxSize: 5242880,
  maxFiles: 4,
  accept: [".pdf", ".docx"],
});
const restValue = ref(null);
const store = useStore();
const route = useRoute();
const loader = ref(false);
const complaintId = ref(route.params.complaintId);
const acceptedFiles = ref([]);
const isFileUploaded = ref({ flag: true, msg: "" });
const errors = ref({
  acceptedFiles: null,
  quillContent: null,
});

const props = defineProps({
  complaintUpdates: {
    type: Array,
  },
  complaintStatus: {
    type: Number,
  },
});

const { complaintUpdates, complaintStatus } = toRefs(props);

function onDrop(acceptedFilesNew, rejectReasons) {
  if (acceptedFiles.value?.length + acceptedFilesNew?.length > 4) {
    isFileUploaded.value.flag = false;
    isFileUploaded.value.msg = "You can upload 4 files at max!";
  } else {
    acceptedFiles.value = [...acceptedFiles.value, ...acceptedFilesNew];
    isFileUploaded.value.flag = true;
    isFileUploaded.value.msg = "";
    if (rejectReasons) {
      rejectReasons.forEach((item) => {
        if (item.errors[0].message.includes("Too many files")) {
          isFileUploaded.value.flag = false;
          isFileUploaded.value.msg = "You can upload 4 files at max!";
        }
        if (
          item.errors[0].message.includes("File is larger than 5242880 bytes")
        ) {
          isFileUploaded.value.flag = false;
          isFileUploaded.value.msg = "File Size Must Be Smaller Than 5MB";
        }
      });
    }
    restValue.value = rest;
  }
}

const removeSingleFile = (id) => {
  if (acceptedFiles.value.length === 1) {
    acceptedFiles.value = [];
  } else {
    acceptedFiles.value.splice(id, 1);
  }
};

const quillContent = ref("");
const contentLimit = 1000;
const contentLimitReached = ref(false);

watch(quillContent, (newValue) => {
  if (newValue.length > contentLimit) {
    errors.value.quillContent = "Maximum Content Limit Reached";
    contentLimitReached.value = true;
  } else {
    errors.value.quillContent = "";
    contentLimitReached.value = false;
  }
});

const validateAndSubmit = () => {
  let valid = true;
  if (!quillContent.value) {
    errors.value.quillContent = "Content cannot be empty.";
    valid = false;
  } else {
    if (!contentLimitReached.value) {
      errors.value.quillContent = null;
    }
  }
  if (valid && !contentLimitReached.value) {
    const formdata = new FormData();
    formdata.append("update", quillContent.value);
    if (acceptedFiles.value) {
      acceptedFiles.value.forEach((file, index) => {
        formdata.append(`files[${index}]`, file);
      });
    }
    loader.value = true;
    formdata.append("complaint", complaintId.value);
    store
      .dispatch("hqComplaints/updateComplaint", formdata)
      .then(() => {
        loader.value = false;
        Swal.fire({
          toast: true,
          position: "top-end",
          title: `Updates Posted`,
          iconHtml: `<img src="${SuccessIcon}" />`,
          width: "32em",
          customClass: {
            title: "popup-title",
            popup: "popup-border",
            content: "popup-text",
            icon: "popup-icon-border",
          },
          timer: 2000,
          showConfirmButton: false,
          background: `rgba(225, 249, 240, 1)`,
        });
      })
      .catch(() => {
        loader.value = false;
      });
  } else {
    return;
  }
};
</script>

<style scoped>
::v-deep .ql-toolbar {
  border-color: rgba(0, 156, 156, 0.479) !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  background-color: #f9ffff !important;
}
</style>
